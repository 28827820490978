import { render, staticRenderFns } from "./CoreBlockFooter.vue?vue&type=template&id=46aada50"
import script from "./CoreBlockFooter.vue?vue&type=script&lang=js"
export * from "./CoreBlockFooter.vue?vue&type=script&lang=js"
import style0 from "./CoreBlockFooter.vue?vue&type=style&index=0&id=46aada50&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../Core.Generator/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreBlockFooterDisclosures: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterDisclosures.vue').default,CoreBlockFooterBanner: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterBanner.vue').default,CoreBlock: require('/usr/src/app/Core.Library/src/components/block/CoreBlock.vue').default,CoreBlockFooterLogo: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterLogo.vue').default,CoreBlockNavItem: require('/usr/src/app/Core.Library/src/components/block/CoreBlockNavItem.vue').default,CoreBlockFooterOneTrust: require('/usr/src/app/Core.Library/src/components/nyl/block/footer/CoreBlockFooterOneTrust.vue').default})
